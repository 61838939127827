import { createSchema } from 'morphism';
import { LogoProps } from 'libs/growth-platform-brand-system-v2/src/components/Logo';

export const schemaLogo = createSchema<LogoProps, any>({
  loading: {
    path: '',
    fn: () => 'eager',
  },
  as: {
    path: '',
    fn: () => 'img',
  },
  src: {
    path: 'url',
    fn: (propertieValue, source) => {
      return source?.gatsbyImageData?.images?.fallback?.src || propertieValue;
    },
  },

  alt: {
    path: 'alt',
    fn: (propertieValue) => propertieValue || 'client-logo',
  },
  width: {
    path: 'width',
    fn: (propertieValue, source) =>
      source?.gatsbyImageData?.width || propertieValue,
  },
  height: {
    path: 'height',
    fn: (propertieValue, source) =>
      source?.gatsbyImageData?.height || propertieValue,
  },
  isEncapsulated: 'isEncapsulated',
  isAnimated: 'isAnimated',
});


export const schemaLogoList = createSchema<
  LogoProps & { isEncapsulated?: boolean; isAnimated?: boolean },
  any
>({
  ...schemaLogo,
  className: 'className',
});
