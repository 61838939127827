import { graphql } from 'gatsby';
import { HeroVideo } from 'libs/growth-platform-brand-system-v2/src/templates/HeroVideo';
import { useTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { morphism } from 'morphism';
import React, { useEffect } from 'react';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { schemaHeroVideo } from '../../morphismSchema/templates/schemaHeroVideo';
import { applySectionMargin } from '../../utils/utils';
import { datadogLogs } from '@datadog/browser-logs';
// import { HubspotForm } from 'libs/growth-platform-brand-system-v2/src/components/HubspotForm';

export const Head = ({ data, pageContext, location }: any) => (
  <HeadWrapper
    data={data}
    pageContext={pageContext}
    location={location}
    templateName="ThankYou"
    dataName={'datoCmsThankYouPage'}
    largestContentfullPaintImg={null}
  />
);
export const query = graphql`
  query DatoThankYouPage($locale: String, $id: String) {
    datoCmsThankYouPage(locale: $locale, id: { eq: $id }) {
      ...ThankYouPage
    }
  }
`;

const ThankYou = ({ data, pageContext }: any) => {
  const { trackingPage } = useTracking();

  const page: any = data.datoCmsThankYouPage;
  const screenSize = useScreenSize();
  const _data = morphism(schemaBaseTemplate, {
    ...page,
    screenSize,
    pageContext,
  });

  useEffect(() => {
    datadogLogs.logger.info('Thank you page', {submitted: (window as any).DEMO_FORM_SUBMITTED});
  }, []);

  // TO BE REIMPLEMENTED WHEN HUBSPOT IS READY
  // const [scriptLoaded, setScriptLoaded] = useState(false);

  if (!page) return <></>;

  return (
    <Layout
      pageContext={pageContext}
      data={page}
      customHeader={page.header}
      templateName="ThankYou"
    >
      <HeroVideo
        {...morphism(schemaHeroVideo, { ..._data?.hero, trackingPage })}
        className={applySectionMargin(0, [])}
      />
      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} />}

      {
        // TO BE REIMPLEMENTED WHEN HUBSPOT IS READY
        /*
        {page?.hubspotFormId && (
        <Script
          src="https://js.hsforms.net/forms/v2.js"
          onLoad={() => setScriptLoaded(true)}
        />
      )}
        <section
        className="bg-blue-301 flex justify-center py-64 mb-104"
        id={hero.ctaButton[0]?.title.replace(/\W+/g, "-")}
      >
        <div className="bg-neutral-1 max-w-820 w-11/12 md:w-7/12 py-24 flex justify-center rounded-12 ">
          <div className="bg-neutral-1 w-11/12 md:w-9/12">
            <HubspotForm
              formId={page.hubspotFormId}
              portalId={page.hubspotPortalId}
              center={true}
              scriptLoaded={scriptLoaded}
            />
          </div>
        </div>
      </section> */
      }
    </Layout>
  );
};

export default ThankYou;
