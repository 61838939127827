import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { LogoProps } from 'libs/growth-platform-brand-system-v2/src/components/Logo';
import { HeroVideoProps } from 'libs/growth-platform-brand-system-v2/src/templates/HeroVideo';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import {
  getGoogleAnalyticsId,
  track,
} from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { classNamesVideoThumbnail } from 'libs/growth-platform-brand-system-v2/src/utils/videoUtils';
import { createSchema, morphism } from 'morphism';
import React from 'react';
import ReactPlayer from 'react-player';
import { TrackingVideo } from '../../components/TrackingVideo';
import { schemaImage } from '../components/schemaImage';
import { schemaDatoButton } from '../schemaDatoButton';
import { schemaLogoList } from '../components/schemaLogoList';

export const schemaHeroVideo = createSchema<HeroVideoProps, any>({
  backgroundColor: 'backgroundColor',
  title: 'title',
  subtitle: 'subtitle',
  tagline: 'tagline',
  onClickVideoThumbnail: {
    path: [],
    fn: () => {
      return () =>
        track({
          gtm: {
            event: 'Toggle Interaction',
            properties: {
              componentName: 'HeroVideo',
              toggleLabel: 'NA/button open video modal',
              gaClientID: getGoogleAnalyticsId(),
            },
          },
        });
    },
  },
  ctaButton: {
    path: ['ctaButton', 'locale'],
    fn: ({ ctaButton, locale }: any) => {
      if (ctaButton) {
        const btns = ctaButton.reduce((acc: any[], btn: any, key: number) => {
          if (btn?.href?.includes('app.payfit.live')) {
            const cta = morphism(schemaDatoButton, {
              ...btn,
              className: 'w-full md:w-auto hidden lg:flex',
              size: 'big',
              locale,
            });

            acc.push(
              <ButtonTracking
                component={ButtonV2}
                index={key}
                section="heroVideo"
                {...cta}
                labelTracking={cta.title}
                key={key}
              />,
            );
          } else {
            const cta = morphism(schemaDatoButton, {
              ...btn,
              className: classNames('w-full', 'md:w-auto'),
              size: 'big',
              locale,
            });
            acc.push(
              <ButtonTracking
                component={ButtonV2}
                index={key}
                section="heroVideo"
                {...cta}
                labelTracking={cta.title}
                key={key}
              />,
            );
          }

          return acc;
        }, []);

        return btns;
      }
      return;
    },
  },
  activeModal: 'activeModal',
  videoButton: {
    path: ['videoButton', 'locale'],
    fn: ({ videoButton, locale }: any) => {
      if (videoButton?.[0]) {
        const { href, rel, ...btn } = morphism(schemaDatoButton, {
          ...videoButton?.[0],
          locale,
        });
        return btn ? { ...btn, as: 'span' } : undefined;
      }
      return;
    },
  },
  videoImage: {
    path: 'videoImage',
    fn: (propertyValue, source) => {
      if (propertyValue?.url || propertyValue?.gatsbyImageData) {
        return morphism(schemaImage, {
          ...propertyValue,
          isHeader: true,
        });
      }

      if (source?.vimeoImage?.url || source?.vimeoImage?.gatsbyImageData) {
        return morphism(schemaImage, {
          ...source.vimeoImage,
          isHeader: true,
        });
      }
      return;
    },
  },
  VideoPlayer: {
    path: ['videoLink', 'vimeoLink'],
    fn: ({ videoLink, vimeoLink }) => {
      return <TrackingVideo url={videoLink || vimeoLink} />;
    },
  },
  ShortVideo: {
    path: 'shortVideo',
    fn: (propertyValue) => {
      if (propertyValue) {
        return (
          <ReactPlayer
            url={propertyValue?.video?.mp4Url || propertyValue?.shortVideo?.url}
            className={classNamesVideoThumbnail}
            volume={0}
            muted={true}
            playing={true}
            loop={true}
            controls={false}
            config={{
              vimeo: {
                playerOptions: {
                  responsive: true,
                  title: false,
                  byline: false,
                  color: '0F6FDE',
                },
              },
            }}
          />
        );
      }
      return;
    },
  },
  logosListTitle: 'logosListTitle',
  logosList: {
    path: 'logosList',
    fn: (propertyValue: any) => {
      const logos = propertyValue?.reduce((acc: LogoProps[], elt: any) => {
        if (elt?.logo?.url) {
          acc.push(morphism(schemaLogoList, elt.logo));
        }
        return acc;
      }, []);

      return logos;
    },
  },
  trustpilotWidget: 'trustpilotWidget',
  centerContent: 'centerContent',
  splitBackground: 'splitBackground',
  ctaLightboxTitle: 'ctaLightboxTitle',
  ctaButtonLightbox: {
    path: ['ctaButtonLightbox', 'locale'],
    fn: ({ ctaButtonLightbox, locale }) => {
      if (ctaButtonLightbox?.[0]) {
        const cta = morphism(schemaDatoButton, {
          ...ctaButtonLightbox[0],
          locale,
        });

        return (
          <ButtonTracking
            component={ButtonV2}
            {...{
              ...cta,
              size: 'big',
            }}
            labelTracking={cta.title}
          />
        );
      }
      return;
    },
  },
});
